exports.components = {
  "component---src-components-project-details-project-details-js": () => import("./../../../src/components/ProjectDetails/projectDetails.js" /* webpackChunkName: "component---src-components-project-details-project-details-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourwork-js": () => import("./../../../src/pages/ourwork.js" /* webpackChunkName: "component---src-pages-ourwork-js" */),
  "component---src-pages-services-angular-js": () => import("./../../../src/pages/services/angular.js" /* webpackChunkName: "component---src-pages-services-angular-js" */),
  "component---src-pages-services-ionic-js": () => import("./../../../src/pages/services/ionic.js" /* webpackChunkName: "component---src-pages-services-ionic-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-node-js": () => import("./../../../src/pages/services/node.js" /* webpackChunkName: "component---src-pages-services-node-js" */),
  "component---src-pages-services-reactjs-js": () => import("./../../../src/pages/services/reactjs.js" /* webpackChunkName: "component---src-pages-services-reactjs-js" */),
  "component---src-pages-services-reactnative-js": () => import("./../../../src/pages/services/reactnative.js" /* webpackChunkName: "component---src-pages-services-reactnative-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

